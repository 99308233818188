<template>
  <div class="setting">
    <Card :parent_id="parent_id" style="margin-bottom: 31px">
      <p class="ti">权限设置</p>
    </Card>
    <div class="sett-box1">
      <p>浏览资料权限</p>
      <p style="margin-top: 24px; margin-left: 34px; color: #000">
        设置谁能浏览你的资料
      </p>
      <div class="choice">
        <el-radio-group v-model="member" @change="memberChange">
          <el-radio :label="0">所有会员可见</el-radio>
          <el-radio :label="1">所有会员不可见</el-radio>
          <!-- 不可见原因 -->
          <div class="invisible-box" v-show="member == 1">
            <p>请选择关闭个人资料的原因：</p>
            <el-radio-group v-model="inMember">
              <el-radio :label="1">我已找到正在交往的对象</el-radio>
              <el-radio :label="2">我已找到真爱，即将踏上红毯</el-radio>
              <el-radio :label="3">我最近忙，无法及时回复邮件</el-radio>
            </el-radio-group>
          </div>
          <el-radio :label="2">部分会员不可见</el-radio>
          <div class="partInvisible-box" v-show="member == 2">
            <div class="partInvisible-box1">
              <p>输入要屏蔽的会员ID：</p>
              <el-input v-model="ljshield" placeholder=""></el-input>
              <div class="box-btn" @click="pb">立即屏蔽</div>
            </div>
            <div class="partInvisible-box2">
              <p>被屏蔽会员列表</p>
              <p>
                取消勾选，保存后即可恢复互动动作（包括发送邮件、打招呼、加关注等）
              </p>
              <ul>
                <li v-for="(item, index) in shields" :key="index">
                  <el-checkbox v-model="item.iSchecked" @change="fn(item)"
                    >{{ item.shieldMemberName }}（ID{{
                      item.shieldMemberId
                    }}）</el-checkbox
                  >
                </li>
                <!-- <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="item in shields"
                  :label="item.shieldMemberName"
                  :key="item.shieldMemberId"
                  >{{ item.shieldMemberName }}</el-checkbox
                >
              </el-checkbox-group> -->
              </ul>
              <div>
                <!-- <el-checkbox-group
                  v-model="checkedCities"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    v-for="city in cities"
                    :key="city.shieldMemberId"
                    :label="city.shieldMemberId"
                    :v-model="city.shieldMemberId"
                    @change="fn(city.shieldMemberId)"
                    >{{ city.shieldMemberName }}（ID{{
                      city.shieldMemberId
                    }}）</el-checkbox
                  >
                </el-checkbox-group> -->
              </div>
            </div>
          </div>
        </el-radio-group>
      </div>
    </div>
    <div class="sett-box2">
      <p>短信送达权限</p>
      <p style="margin-top: 24px; margin-left: 34px; color: #000">
        对也在网及其他会员发送的联系短信通知（免费），你希望：
      </p>
      <div style="padding: 12px 34px">
        <el-radio-group v-model="message">
          <el-radio :label="0">开启短信提醒</el-radio>
          <el-radio :label="1">关闭短信提醒</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="Btn">
      <div class="Btn-box" @click="preservation">保存</div>
    </div>

    <!-- 提示所有会员不可见 -->
    <div class="tks" v-if="iSShyness">
      <div class="SF">
        <div class="Sf-title">
          <p>提示</p>
          <p class="el-icon-close" @click="istkClose()"></p>
        </div>
        <!-- 主要内容 -->
        <div class="Sf-content">
          <!-- <img src="../../assets/images/gold/ganTao.png" alt="" /> -->
          <p>请问是否设置“所有会员不可见”</p>
        </div>
        <div class="Sf-Btn">
          <div class="Sf-Btn-left" @click="istkClose()">
            <p>取消</p>
          </div>
          <div class="Sf-Btn-right" @click="istkCloseQR()">
            <p>确认</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import {
  configList,
  configUpdate,
  // configShield,
  subTk,
} from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { infocur, shield } from "@/api/login/login.js";
// import { shield } from "@/api/login/login.js";
export default {
  data() {
    return {
      parent_id: "", //zujian
      member: 0,
      message: 0,
      invisible: false,
      inMember: 1,
      partInvisible: false,
      ljshield: "",
      checked: true,
      memberId: "",
      messageId: "",
      shields: [],
      iSShyness: false,
      //测试
      isIndeterminate: true,
      checkAll: false,
      checkedCities: [],
    };
  },
  mounted() {
    // this.Message();
    this.configList();
    this.Tkl();
  },
  components: {
    Card,
  },
  methods: {
    // handleCheckAllChange(val) {
    //   this.checkedCities = val ? this.shields : [];
    //   this.isIndeterminate = false;
    // },
    // handleCheckedCitiesChange(value) {
    //   let checkedCount = value.length;
    //   this.checkAll = checkedCount === this.cities.length;
    //   this.isIndeterminate =
    //     checkedCount > 0 && checkedCount < this.cities.length;
    // },
    async fn(i) {
      console.log("数据", i);
      this.Tkl();
      if (i.iSchecked) {
        //取消屏蔽
        const { code, msg } = await shield({
          opFlag: 0,
          shieldMemberId: i.shieldMemberId,
        });
        if (code == 0) {
          this.$message({
            message: msg,
            type: "success",
            offset: 100,
          });

          // this.pb();
        } else if (code == 1) {
          this.$message.error({
            message: msg,
            offset: 100,
          });
        }
      } else {
        //取消屏蔽
        const { code, msg } = await shield({
          opFlag: 1,
          shieldMemberId: i.shieldMemberId,
        });
        if (code == 0) {
          this.$message({
            message: msg,
            type: "success",
            offset: 100,
          });

          // this.pb();
        } else if (code == 1) {
          this.$message.error({
            message: msg,
            offset: 100,
          });
        }
      }
    },
    istkClose() {
      this.iSShyness = false;
    },
    async Tkl() {
      const G = await subTk();
      if (G.code == 0) {
        local.SessionSet("sub_tk", G.data);
      }
    },
    async configList() {
      let a = local.get("access_token");
      const c = await infocur(a);
      if (c.code == 0) {
        this.parent_id = c.data.user.id;
      }
      this.shields = [];
      const { code, data } = await configList();
      if (code == 0) {
        console.log("数据", data[0].configObjValue);
        this.member = Number(data[0].configObjValue);
        this.message = Number(data[1].configObjValue);
        this.inMember = Number(data[0].remark);
        this.memberId = data[0].id;
        this.messageId = data[1].id;
        // this.shields = data[0].shields;
        console.log("____");
        if (data[0].shields) {
          data[0].shields.map(async (item) => {
            this.shields.push(
              Object.assign({}, item, {
                iSchecked: true,
              })
            );
          });
        }

        this.cities = data[0].shields;
      }
    },
    async ziliao() {
      let a = {
        configObjKey: "perm:browse:member",
        configObjName: "浏览资料权限",
        configObjValue: this.member,
        id: this.memberId,
        remark: this.inMember,
      };
      const { code, data } = await configUpdate(a);
      if (code == 0) {
        console.log("数据", data);
        this.Tkl();
      }
    },
    async duanX() {
      let b = {
        configObjKey: "perm:sms:receive",
        configObjName: "短信送达权限",
        configObjValue: this.message,
        id: this.messageId,
      };
      const Dt = await configUpdate(b);
      if (Dt.code == 0) {
        console.log("数据", Dt.data);
        this.$message({
          message: "修改成功",
          type: "success",
          offset: 100,
        });
        this.Tkl();
      }
    },
    memberChange(value) {
      console.log("+", value);
      // if(value == 2){
      //   this.configList()
      // }
    },
    async preservation() {
      // console.log('___',this.member);
      if (this.member == 1) {
        this.iSShyness = true;
      } else {
        this.ziliao();
        this.duanX();
        this.Tkl();
      }
    },
    istkCloseQR() {
      this.ziliao();
      this.duanX();
      this.Tkl();
      this.iSShyness = false;
    },
    async pb() {
      // let a = {
      //   shieldMemberId: this.ljshield,
      // };
      // const { code, data } = await configShield(a);
      this.Tkl();
      const { code } = await shield({
        opFlag: 0,
        shieldMemberId: this.ljshield,
      });
      if (code == 0) {
        // console.log("数据", data);
        // this.configList();
        // this.Tkl();
        this.configList();
        this.$message({
          message: "屏蔽成功",
          type: "success",
          offset: 100,
        });
      } else {
        this.$message({
          message: "屏蔽成功",
          type: "error",
          offset: 100,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  // width: 740px;
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #fa5858;
    background: #fa5858;
  }
  /deep/ .el-radio {
    color: #333;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #333;
  }
  .sett-box1 {
    border-bottom: 1px solid #f4f4f4ff;
    .choice {
      display: flex;
      padding-left: 34px;
      /deep/ .el-radio,
      .el-radio__input {
        line-height: 2;
      }

      /deep/ .el-radio-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100px;
        padding: 16px 0px;
        .invisible-box {
          padding-left: 34px;
          p {
            // margin-top: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
        }
      }
      .partInvisible-box {
        padding-left: 34px;
        p {
          font-size: 14px;
        }
        .partInvisible-box1 {
          display: flex;
          align-items: center;
          /deep/ .el-input {
            width: 200px;
          }
          /deep/ .el-input__inner {
            background-color: #ecececff;
            border: none;
            outline: none;
          }
          .box-btn {
            margin-left: 28px;

            background: #fd686e;
            border-radius: 4px;
            width: 96px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
            // background-color: #fa5858;
          }
        }
        .partInvisible-box2 {
          margin-top: 12px;
          p:nth-child(1) {
            width: 105px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            letter-spacing: 1px;
          }
          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 17px;
          }
          ul {
            li {
              /deep/ .el-checkbox__inner {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  .sett-box2 {
    margin-top: 30px;
    /deep/ .el-radio-group {
      margin-top: 10px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .Btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .Btn-box {
      width: 200px;
      height: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background: #fd686eff;
      border-radius: 20px;
      // margin-bottom: 34px;
    }
  }
}
.tks {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .SF {
    position: fixed;
    top: 200px;
    left: 40%;
    // width: 420px;
    width: 380px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Sf-title {
      line-height: 44px;
      background: #f3f3f3;
      text-align: center;
      color: #999;
      font-size: 16px;
      // width: 420px;
      width: 100%;
      position: relative;
      /deep/.el-icon-close {
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
      }
    }
    .Sf-content {
      margin-top: 38px;
      margin-bottom: 34px;
    }
    .Sf-Btn {
      display: flex;
      margin-bottom: 34px;
      div {
        width: 140px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .Sf-Btn-left {
        border-radius: 4px;
        border: 1px solid #adadad;
        color: #666666;
        margin-right: 20px;
      }
      .Sf-Btn-right {
        background: #fd686e;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
</style>